/// Zappar for ThreeJS
/// Instant Tracking 3D Model

import * as THREE from 'three';
import * as ZapparThree from '@zappar/zappar-threejs';
import Stats from "stats.js";
import { gsap } from 'gsap';

import CustomLoader from './utils/CustomLoader/CustomLoader.js';
import Sizes from "./utils/Sizes.js";
import Time from "./utils/Time.js";
import World from "./World/World.js";
import Zappar from "./World/Zappar.js";
import Resources from './utils/Resources.js';
import sources from './utils/sources.js';

let instance = null;
const stats = new Stats();
const debugMode = false;
let experincePart2 = false;
const gtagID = 'G-KLMGV7NZBK'; // TODO Comment back in
const websiteDomian =  document.domain || ".webar.run";
const constraintsBack = 
    {
    	video : 
        { facingMode : { exact : "environment" }, },
    };
let visibilityChange, hidden;
export default class Project {
	constructor() {
		if ( instance ) {
			return instance;
		}
		instance = this;

		/**
         * setup files
         */
		this.customLoader = new CustomLoader();
		this.sizes = new Sizes(); 
		this.time = new Time(); 
		// Construct our ThreeJS renderer and scene as usual
		this.renderer = new THREE.WebGLRenderer( { antialias : true, } );
		this.scene = new THREE.Scene();
		this.camera = new ZapparThree.Camera();
		this.zappar = new Zappar();
		ZapparThree.glContextSet( this.renderer.getContext() );
		this.resources = new Resources( sources );
		this.world = new World();
        
		/**
         * Debug Mode
         */
		// this.debugModeEnabled()
        
		/**
         *  GTAG Stuff
         */
		window.dataLayer = window.dataLayer || [];
		this.gtag( 'consent', 'default', { 'analytics_storage' : 'denied' } );

		// Check if has GA cookie on the page
		if( document.cookie.length > 0 ) {
			this.gaOptOut();
		}     
		/**
        *  Buttons
        */
		this.loading_content_Page = document.getElementById( "loader-container" ),
		this.html = 
       {
       	cookiesPopUp : 
			{ 
				container : document.querySelector( ".cookiesBanner-Container" ),
				accepted : document.getElementById( 'cookies-accept' ),
				denined : document.getElementById( 'cookies-denined' ),
			},
       	splashScreen : 
            {
            	page : document.getElementById( 'splashScreen' ),
            	lanuch_btn : document.getElementById( 'launch-btn' ),
            	instructions_btn : document.getElementById( 'instructions-btn' ),
            },
       	instructions : 
            {
            	page : document.getElementById( 'instructionsPage' ),
            	lanuch_btn : document.getElementById( 'instructions-launch-btn' ),
            	back_btn : document.getElementById( 'instructions-back-btn' ),
            },
       	gameplay :
            {
            	page : document.getElementById( 'gameplayScreen' ),
            	gameplay_ui : document.getElementById( 'gameplay-ui' ),
            	progressBar_ui : document.getElementById( 'progressBar-ui' ),
            	placementMode_ui : document.getElementById( 'placement-mode-ui' ),
            	place_btn : document.getElementById( 'tap-to-place' ),
            	reCenter_btn : document.getElementById( 'recenter-btn' ),
            	bookEyeTest_btn : document.getElementById( 'bookEyeTest-btn' ),
            	learnMoreOCT_btn : document.getElementById( 'learnMoreOCT-btn' ),
            },
       	booking : 
            {
            	page : document.getElementById( 'bookingPage' ),
            	book_btn : document.getElementById( 'booking-book-btn' ),
            	replay_btn : document.getElementById( 'booking-replay-btn' )
            },
       };
		// this.html.splashScreen.page.style.display = "none" 
	   this.html.splashScreen.lanuch_btn.disabled  = true;
		/**
        * Event Emitters & Listeners
        * These emitters & Listeners can be called immediately after constrcution
        */
		// Once HTML Document is loaded
		document.addEventListener( "DOMContentLoaded", () => {
			this.handleVisibilityChange();
			// Hide Pages - seems to fix lag issues when they are visible off spwan
			this.html.instructions.page.style.display = "flex"; 
			this.html.booking.page.style.display = "flex"; 
			this.html.instructions.page.style.display = "none"; 
			this.html.booking.page.style.display = "none"; 
		} );
        
		this.resources.on( "ready", () => {
			this.createAudio();
			this.setAudioVO( "start" );
			this.customLoader.loaded();
		} );
		// Event emitter for resizing of the window
		this.sizes.on( "resize", () => {
			this.renderer.setSize( this.sizes.width, this.sizes.height );
		} );
		/**
         *  Buttons
         */
		// COOKIES POPUP
		this.html.cookiesPopUp.accepted.addEventListener( 'click', () => {
			this.uiSoundEffect( this.buttonPress_audio );
			this.gaOptIn();
			this.googleAnalytics( "Learn Splash Screen" );
			this.animateCookiesPopUp();
			// this.html.cookiesPopUp.container.style.display = "none";
			// this.html.splashScreen.page.style.filter = "none";
			// this.html.splashScreen.lanuch_btn.disabled  = false;
		} );
		this.html.cookiesPopUp.denined.addEventListener( 'click', () => {
			this.uiSoundEffect( this.buttonPress_audio );
			this.gaOptOut();
			this.animateCookiesPopUp();
			// this.html.cookiesPopUp.container.style.display = "none";
			// this.html.splashScreen.page.style.filter = "none";
			// this.html.splashScreen.lanuch_btn.disabled  = false;
		} );
		// SPLASHSCREEN PAGE
		this.html.splashScreen.lanuch_btn.addEventListener( 'click', () => {
			this.uiSoundEffect( this.buttonPress_audio );
			this.showInstructionsScreen();
		} );
		// INSTRUCTIONS PAGE
		this.html.instructions.lanuch_btn.addEventListener( 'click', () => {
			this.uiSoundEffect( this.buttonPress_audio );
			this.googleAnalytics( "Experience Launched" );
			// this.requestPermisionsOld();
			this.requestPremisions();
		} );
		this.html.instructions.back_btn.addEventListener( 'click', () => {
			this.uiSoundEffect( this.buttonPress_audio );
			this.html.splashScreen.page.style.display = "flex";
			this.html.instructions.page.style.display = "none";
		} );
		// GAMEPLAY PAGE
		this.html.gameplay.bookEyeTest_btn.addEventListener( 'click', () => {
			this.uiSoundEffect( this.buttonPress_audio );
			this.placementMode( true );
			const gtagEventName = experincePart2 ? "Booking CTA Part2" : "Booking CTA Part1";
			const gtagEventNameURL = gtagEventName.replace( / /g, "_" );
            
			this.googleAnalytics( gtagEventName );
			setTimeout( () => {
				window.open( `https://www.boots.com/opticiansappointments?utm_source=bootsopticians-ar.com&utm_medium=referral&utm_campaign=opticians%7Car&utm_content=${gtagEventNameURL}`, '_blank' );         
			}, 1 );
		} );
		this.html.gameplay.learnMoreOCT_btn.addEventListener( 'click', () => {
			this.uiSoundEffect( this.buttonPress_audio );
			this.googleAnalytics( "Learn More Part1" );
			this.learnMoreOCTScans();
			experincePart2 = true;
		} );
		this.html.gameplay.place_btn.addEventListener( 'click', () => {
			this.uiSoundEffect( this.TapToPlace_auido );
			this.placementMode( false );
		} );
		this.html.gameplay.reCenter_btn.addEventListener( 'click', () => {
			this.uiSoundEffect( this.buttonPress_audio );
			this.placementMode( true );
		} );
		// BOOKING PAGE 
		this.html.booking.book_btn.addEventListener( 'click', () => {
			this.uiSoundEffect( this.buttonPress_audio );
			this.googleAnalytics( "Booking CTA End" );
			window.open( 'https://www.boots.com/opticiansappointments?utm_source=bootsopticians-ar.com&utm_medium=referral&utm_campaign=opticians%7Car&utm_content=Booking_CTA_End', '_blank' );
		} ); 
		this.html.booking.replay_btn.addEventListener( 'click', () => {
			this.uiSoundEffect( this.buttonPress_audio );
			this.resetGame();
			this.googleAnalytics( "Replayed Experience" );
		} );
	}
	init() {
		
		this.setInstance();
		this.setCamera();
		/**
         * Rednerer
         */
		this.renderer.shadowMap.enabled = true;
		this.renderer.shadowMap.type = THREE.PCFSoftShadowMap;
		this.renderer.toneMapping = THREE.ACESFilmicToneMapping;
		this.renderer.toneMappingExposure = 0.7;
		this.renderer.physicallyCorrectLights = true;

		this.renderer.outputColorSpace = THREE.sRGBEncoding;
		this.renderer.outputEncoding = THREE.sRGBEncoding;

		let canavs = document.body.appendChild( this.renderer.domElement );
		canavs.id ="canvas";
		this.renderer.setSize( this.sizes.width, this.sizes.height );

		// Request Zappar Permisions after camera has been set to device ID camera source
		this.zappar.RequestCameraPermisions( ( beenAccepted ) => { 
			if( !beenAccepted ) {
				this.googleAnalytics( "Camera Permissions Denied" );
				return;
			}
			this.StartGame(); 
			this.googleAnalytics( "Camera Permissions Accepted" );
			this.customLoader.loaded(); // Remove the Loader as the content has been loaded
		} );
        
		/**
         *  Event Emitters
         *  These emitters wont be called until after camera permisions 
         */
		// Event emitter for tick (every frame)
		this.time.on( "tick", () => {
			this.update(); 
		} );
	}
	// Unblur and Animation banner off the screen
	animateCookiesPopUp() {
		gsap.to( ".cookiesBanner-Container", { bottom : -550, duration : 1 } );
		gsap.to( ".splashScreen", {
			filter : "blur(0px)",
			duration : 1,
			onComplete : ()=>{
				this.html.cookiesPopUp.container.style.display = "none";
				this.html.splashScreen.page.style.filter = "none";
				this.html.splashScreen.lanuch_btn.disabled  = false;
			} 
		} );
	}
	setInstance () {
		this.hasPlaced = false;
		this.initalPlacement = true;
		experincePart2 = false;
	}
	requestPremisions() {
		this.customLoader.loading();
		navigator.mediaDevices.getUserMedia( constraintsBack ).then( ( mediaStream ) => {
			const backCameraID = mediaStream.getVideoTracks()[ 0 ].getSettings().deviceId;
			this.camera = new ZapparThree.Camera( { rearCameraSource : backCameraID } );
			this.init(); 
		} )
			.catch( ()=> {
				navigator.mediaDevices.getUserMedia( { video : true } ).then( ( mediaStream ) => {
					this.init(); 
				} )
					.catch( ( error ) => {
						console.log( error ); 
						this.googleAnalytics( "Camera Permissions Denied" );
						this.zappar.permissionDenied();
					} );
			} );
	}
	handleVisibilityChange() {
		// Check device to understand which prefix to use
		// for visibility changes
		if ( document.hidden !== undefined ) { // Opera 12.10 and Firefox 18 and later support
			hidden = "hidden";
			visibilityChange = "visibilitychange";
		} 
		else if ( document.mozHidden !== undefined ) {
			hidden = "mozHidden";
			visibilityChange = "mozvisibilitychange";
		} 
		else if ( document.msHidden !== undefined ) {
			hidden = "msHidden";
			visibilityChange = "msvisibilitychange";
		} 
		else if ( document.webkitHidden !== undefined ) {
			hidden = "webkitHidden";
			visibilityChange = "webkitvisibilitychange";
		} 
		else if ( document.oHidden !== undefined ) {
			hidden = "oHidden";
			visibilityChange = "ovisibilitychange";
		}          
		document.addEventListener( visibilityChange, () => {
			// If the page is hidden, toggle placement mode
			if ( document[hidden] && this.hasPlaced ) {
				this.placementMode( true );
				console.log( document[hidden] );
			}
		}, false );
	}
	StartGame() {
		this.zappar.enableInstanceTracker();
		// this.uiSoundEffect(this.buttonPress_audio)
		this.html.splashScreen.page.style.display = "none";
		this.html.instructions.page.style.display = "none";
		this.html.gameplay.page.style.display = "flex";
		setTimeout( () => {
			this.initalPlacement = true;
			this.setAudioVO( "launched", () => {
				this.world.setVoiceAudioClip( "part1" );
				this.placementMode( true );
                
			} );
		}, 0 );
	}
	resetGame() {
		this.onBookingPage = false;
		this.setInstance();
		this.html.booking.page.style.display = "none";
		this.html.gameplay.learnMoreOCT_btn.style.display = "block";
		this.StartGame();
		this.world.setInstance();
	}
	showInstructionsScreen() {
		this.html.splashScreen.page.style.display = "none";
		this.html.instructions.page.style.display = "flex";
	}
	setCamera() {
		// Set the background of our scene to be the zappar camera background texture
		this.scene.background = this.camera.backgroundTexture;
		this.camera.poseMode = ZapparThree.CameraPoseMode.AnchorOrigin;
		this.camera.backgroundTexture.encoding = THREE.sRGBEncoding;
	}
	/**
     * Audio Loader
     */
	createAudio() {
		const audioListener = new THREE.AudioListener();
		this.camera.add( audioListener );
		this.BillieVO_Part1_Audio = new THREE.Audio( audioListener );
		this.billieVo_part01 = this.resources.items.billieVo_part01;
		this.BillieVO_Part2_Audio = new THREE.Audio( audioListener );
		this.billieVo_part02 = this.resources.items.billieVo_part02;
		this.buttonPress_audio = new THREE.Audio( audioListener );
		this.buttonPress_sound = this.resources.items.buttonPress;
		this.TapToPlace_auido = new THREE.Audio( audioListener );
		this.TapToPlace_sound = this.resources.items.TapToPlace_sound;
		this.arcadeGamePop_audio = new THREE.Audio( audioListener );
		this.arcadeGamePop_sound = this.resources.items.arcadeGamePop_sound;
	}
	setAudioVO( section, callback ) {
		if( section === "start" ) {
			this.buttonPress_audio.setBuffer( this.buttonPress_sound );
			this.buttonPress_audio.setVolume( 0.5 );
			this.TapToPlace_auido.setBuffer( this.TapToPlace_sound );
			this.TapToPlace_auido.setVolume( 0.15 );
		}
		else if( section === "launched" ) {
			this.BillieVO_Part1_Audio.setBuffer( this.billieVo_part01 );
			this.BillieVO_Part1_Audio.setVolume( 1 );
			this.BillieVO_Part2_Audio.setBuffer( this.billieVo_part02 );
			this.BillieVO_Part2_Audio.setVolume( 1 );
			this.arcadeGamePop_audio.setBuffer( this.arcadeGamePop_sound );
			this.arcadeGamePop_audio.setVolume( 0.3 );
		}
        
		if ( callback ) callback();
	}
	/**
     * Audio Loader
     */
	uiSoundEffect ( clip ) {
		if( clip.isPlaying ) clip.stop();
		clip.play();
	}
	placementMode( bool ) {
		// Fixes error in Asset3D file as it tries to set the aniamtion 
		// and audio to offset position but there is no vaild clip 
		// or audio clip when on Booking page
		if( this.onBookingPage ) return;
		if ( bool ) {
			// this.hasPlaced = false;
			this.html.gameplay.placementMode_ui.style.display = "block";
			this.html.gameplay.gameplay_ui.style.display = "none";
			this.world.placementMode( true );
		}
		else if( !bool ) {
			// this.hasPlaced = true;
			this.html.gameplay.placementMode_ui.style.display = "none";
			this.html.gameplay.gameplay_ui.style.display = "block";
			this.world.placementMode( false );
		}
	}
	learnMoreOCTScans() {
		this.html.gameplay.learnMoreOCT_btn.style.display ="none";
		this.world.learnMoreOCTScans();
	}
	bookEyeTest() {
		this.onBookingPage = true;
		this.html.gameplay.page.style.display = "none";
		this.html.booking.page.style.display = "block";
		this.world.stopAllAnimations();
		this.zappar.disableInstanceTracker();
	}
	gtag() { 
		dataLayer.push( arguments ); 
	}
	googleAnalytics( eventName ) {
		if( document.getElementById( "google-analytics" ) ) {
			this.gtag( "event", eventName );
			console.log( `event = ${eventName}` );
		}
	}
	gaOptIn() {
		let gaScript = document.createElement( "script" );
		gaScript.id = 'google-analytics';
		gaScript.src = `https://www.googletagmanager.com/gtag/js?id=${ gtagID }`;
		document.head.appendChild( gaScript );

		// window.dataLayer = window.dataLayer || [];
		
		// Update cookie conset to allow analytical cookies
		this.gtag( 'consent', 'update', { 'analytics_storage' : 'granted' } );

		this.gtag( 'js', new Date() );
		this.gtag( 'config', gtagID );
		if( window.ga && ga.loaded ) {
			// console.log( window.ga );
			// console.log( "window.ga" );
		}
	}
	
	gaOptOut() {
		const allCookies = document.cookie.split( ";" );
		for ( let i = 0; i < allCookies.length; i++ ) {
			document.cookie = allCookies[ i ] + "=; expires=Thu, 01 Jan 1970 00:00:01 GMT" + "; domain=" + websiteDomian + "; path=/";
		}
    
	}
	updateProgressBarPercentage( elapsedPercentage ) {
		this.html.gameplay.progressBar_ui.style.width = elapsedPercentage + "%";
	}
	debugModeEnabled() {
		stats.showPanel( 0 ); // 0: fps, 1: ms, 2: mb, 3+: custom
		document.body.appendChild( stats.dom );
	}
	/**
     *  Game Loop
    */
	update() {
		if( debugMode ) {
			stats.begin();

			stats.end();
		}
		this.zappar.update();
		this.world.update();

		if( this.camera ) {
			// The Zappar camera must have updateFrame called every frame
			this.camera?.updateFrame( this.renderer );
			// Draw the ThreeJS scene in the usual way, but using the Zappar camera
			this.renderer.render( this.scene, this.camera );
		}
	}
}