export default 
[
	{
		name : "assets3D",
		type : "gltfModel",
		path :
        [
        	new URL( "../../../assets/Billie_Anim_v7.glb", import.meta.url ).href
        ]
	}, 
	{
		name : "equipment3D",
		type : "gltfModel",
		path :
        [
        	new URL( "../../../assets/Boots_Equipment_Assets_v4.glb", import.meta.url ).href
        ]
	},
	{
		name : "billieVo_part01",
		type : "audio",
		path :
        [
        	new URL( "../../../assets/Audio/BillieVO-part01_combined.mp3", import.meta.url ).href
        ]
	},
	{
		name : "billieVo_part02",
		type : "audio",
		path :
        [
        	new URL( "../../../assets/Audio/BillieVO-part_02.mp3", import.meta.url ).href
        ]
	},
	{
		name : "buttonPress",
		type : "audio",
		path :
        [
        	new URL( "../../../assets/Audio/button_Click.wav", import.meta.url ).href
        ]
	},
	{
		name : "TapToPlace_sound",
		type : "audio",
		path :
        [
        	new URL( "../../../assets/Audio/TapToPlace_v2.mp3", import.meta.url ).href
        ]
	},
	{
		name : "arcadeGamePop_sound",
		type : "audio",
		path :
        [
        	new URL( "../../../assets/Audio/arcade_Game_Pop.wav", import.meta.url ).href
        ]
	},
	{
		name : "billie_baseColour",
		type : "texture",
		path :
        [
        	new URL( "../../../assets/Textures/billie_lo_mat_billie_BaseColor_v2.jpg", import.meta.url ).href
        ]
	},     
	{
		name : "billie_normalMap",
		type : "texture",
		path :
        [
        	new URL( "../../../assets/Textures/billie_lo_mat_billie_Normal.jpg", import.meta.url ).href
        ]
	},     
	{
		name : "billie_roughnessMap",
		type : "texture",
		path :
        [
        	new URL( "../../../assets/Textures/billie_lo_mat_billie_Roughness.jpg", import.meta.url ).href
        ]
	},     
	{
		name : "billie_aoMap",
		type : "texture",
		path :
        [
        	new URL( "../../../assets/Textures/billie_lo_mat_billie_AO.jpg", import.meta.url ).href
        ]
	},
	// Equipment 01
	{
		name : "equipment_01_baseColour",
		type : "texture",
		path :
        [
        	new URL( "../../../assets/Textures/mat_boots_assets_1_BaseColor.jpg", import.meta.url ).href
        ]
	},     
	{
		name : "equipment_01_normalMap",
		type : "texture",
		path :
        [
        	new URL( "../../../assets/Textures/mat_boots_assets_1_Normal.jpg", import.meta.url ).href
        ]
	},     
	{
		name : "equipment_01_roughnessMap",
		type : "texture",
		path :
        [
        	new URL( "../../../assets/Textures/mat_boots_assets_1_Roughness.jpg", import.meta.url ).href
        ]
	},  
	{
		name : "equipment_01_metallicMap",
		type : "texture",
		path :
        [
        	new URL( "../../../assets/Textures/mat_boots_assets_1_Metallic.jpg", import.meta.url ).href
        ]
	},  
	{
		name : "equipment_01_aoMap",
		type : "texture",
		path :
        [
        	new URL( "../../../assets/Textures/mat_boots_assets_1_AO.jpg", import.meta.url ).href
        ]
	},
	// Equipment 02
	{
		name : "equipment_02_baseColour",
		type : "texture",
		path :
        [
        	new URL( "../../../assets/Textures/Assets_Render_mat_boots_assets_2_BaseColor.jpg", import.meta.url ).href
        ]
	},     
	{
		name : "equipment_02_normalMap",
		type : "texture",
		path :
        [
        	new URL( "../../../assets/Textures/Assets_mat_boots_assets_2_Normal.jpg", import.meta.url ).href
        ]
	},     
	{
		name : "equipment_02_roughnessMap",
		type : "texture",
		path :
        [
        	new URL( "../../../assets/Textures/Assets_mat_boots_assets_2_Roughness.jpg", import.meta.url ).href
        ]
	},  
	{
		name : "equipment_02_metallicMap",
		type : "texture",
		path :
        [
        	new URL( "../../../assets/Textures/Assets_mat_boots_assets_2_Metallic.jpg", import.meta.url ).href
        ]
	},  
];