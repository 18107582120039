import * as THREE from 'three';

import Project from  "../Project.js"; 
import EventEmitter from '../utils/EventEmitter.js';

const fov = 
{
	yaw : [ -0.8, 0.8 ],
	pitch : [ -0.04, 0.05 ]
};
let billieModel, billieAllMaterials; 
let currentAnimClipPart, currentAnimClip_Duration, currentAnimClip_uuid, animationPaused, animationTimeOffset, soundTimeOffset; //, currentAnimClip
let idleAnimationLoopCount;
const animationActionClips = {
	part1 : [],
	idle : [],
	part2 : [],
};
export default class Asset3D extends EventEmitter {
	constructor() {
		super();
		console.log( "Asset3D" );
		this.project = new Project();
		this.zappar = this.project.zappar;
		this.world = this.project.world;
		this.resources = this.project.resources;
		this.time = this.project.time;
        
		this.resource = this.resources.items.assets3D; // glb

		this.baseColour = this.resources.items.billie_baseColour; // texture
		this.normalMap = this.resources.items.billie_normalMap; // texture
		this.roughnessMap = this.resources.items.billie_roughnessMap; // texture
		this.aoMap = this.resources.items.billie_aoMap; // texture
		billieAllMaterials = [ this.baseColour, this.normalMap, this.roughnessMap, this.aoMap ];

		this.setModel();
		this.hasAnimation = true;
		this.currentAnimClip;
		this.setAnimation();
	}
	setModel() {
		this.model = this.resource.scene; //glb
		// Ensure that the meshes cast shadows.
		this.model.traverse( ( child ) => {
			if ( child.isMesh ) { 
				child.castShadow = true; 
				child.receiveShadow = true;
				child.material.vertexColors = false;
			}
		} );
        
		// get refernces on model    
		this.headRefence = this.model.getObjectByName( "Head_Group" );
		this.bodyModel = this.model.getObjectByName( "Billie_M_Body" );
		this.headModel = this.model.getObjectByName( "M_Billie_Head" );
		this.hairModel = this.model.getObjectByName( "M_Billie_Glasses" );
		this.glassesModel = this.model.getObjectByName( "Glasses" );
		billieModel = [ this.headModel, this.bodyModel, this.hairModel, this.glassesModel ];
        
		// Set Textures for Billie
		billieAllMaterials.map( ( texture ) => {
			texture.flipY = false; // needed due to GLB files
			// texture.encoding = THREE.sRGBEncoding; // doing all messes it up
		} );
		this.baseColour.encoding = THREE.sRGBEncoding;
        
		this.billieMaterial = new THREE.MeshStandardMaterial(
			{
				map : this.baseColour,
				normalMap : this.normalMap,
				roughnessMap : this.roughnessMap,
				metalness : 0,
				aoMap : this.aoMap,
				aoMapIntensity : 1.5,
				envMapIntensity : 0,
				toneMapped : false,
			} );
		billieModel.map( ( model ) => {
			model.material = this.billieMaterial;
		} );
              
		// Create placement material
		this.opacityMat = new THREE.MeshBasicMaterial(
			{
				color : "grey",
				transparent : true,
				opacity : 0.7
			} );
		// Store the current Material 
		// this.billieMaterial = this.bodyModel.material // glb
		// this.billieMaterial.normalMap = null

		this.zappar.instantTrackerGroup.add( this.model );

		// Temp postion Object for lookAt, has to be added to the scene inorder for the lookAt to work 
		this.tempObject = new THREE.Mesh();
		this.zappar.instantTrackerGroup.add( this.tempObject );
		// Set the postion of the plane to be at head height
		this.tempObject.position.set( 0, 1.75, 0 );
	}
	setAnimation() {
		this.animMixer = new THREE.AnimationMixer( this.model );
		// Set up Subclips
		this.resource.animations.forEach( ( element ) => {
			const part1Clip = this.animMixer.clipAction( THREE.AnimationUtils.subclip( element, "part1", 255, 2510 ) );
			animationActionClips.part1.push( part1Clip );
			const idelClip = this.animMixer.clipAction( THREE.AnimationUtils.subclip( element, "idle", 2510, 2995 ) );
			animationActionClips.idle.push( idelClip );
			const part2Clip = this.animMixer.clipAction( THREE.AnimationUtils.subclip( element, "part2", 2995, 4680 ) );
			animationActionClips.part2.push( part2Clip );
		} );
		/* this.part1Clip = this.animMixer.clipAction(THREE.AnimationUtils.subclip(this.resource.animations[ 0 ], "part1", 255, 2510))
        this.part1Clip.repetitions = 1 */
		this.animMixer.addEventListener( "finished", ( e ) => {
			if( currentAnimClipPart === "part1" ) {
				this.animMixer.stopAllAction();
				this.playAnimClip( "idle" );
				this.world.idelActions();
			}
			else if( currentAnimClipPart === "idle" && e.action._clip.uuid === currentAnimClip_uuid && idleAnimationLoopCount !== 2 ) {
				idleAnimationLoopCount += 1;
				this.animMixer.stopAllAction();
				this.playAnimClip( "idle" );
			}
			else if( currentAnimClipPart === "part2" ) {
				// Once second part of the animation has finished auto show Booking page
				this.project.bookEyeTest();
			}
		} );
	}
	setInstance() {
		this.playAnimClip( "part1" );
		idleAnimationLoopCount = 0;
		animationTimeOffset = 0;
	}
	TogglePlacementMaterial( bool ) {
		const mat = bool ? this.opacityMat : this.billieMaterial;
		billieModel.forEach( ( element ) => {
			element.material = mat;
		} );
	}
	calculateProgressBar( elapsedTime ) {
		let progressPercetage = ( elapsedTime / currentAnimClip_Duration ) * 100;
		progressPercetage = Math.round( progressPercetage * 100000 ) / 100000;
		// this.project.updateProgressBarPercentage( progressPercetage )
		if( currentAnimClipPart !== "idle" ) {
			this.project.updateProgressBarPercentage( progressPercetage );
		}
		if( currentAnimClipPart === "idle" && idleAnimationLoopCount === 2 ) {
			if ( progressPercetage > 98 ) {
				this.pauseAllAnimations( true );
				this.world.setVoiceAudioClip( "idle" );
			}
		}
	}
	// ANIMATION TIMELINES
	deactivateAllAnimations() {
		for( const clips in animationActionClips ) {
			animationActionClips[clips].forEach( ( clip ) => clip.stop() );
		}
	}
	pauseAllAnimations( bool ) {
		animationPaused = bool;
	}
	//TODO setup replay button
	resumeAnimationClip () {
		if ( animationPaused ) return;
		( function () {
			switch ( currentAnimClipPart ) {
			case "part1" :
				animationTimeOffset = 0.133;
				soundTimeOffset = 0;
				break;

			case "idle" :
				animationTimeOffset = 0.133;
                
				soundTimeOffset = ( animationActionClips.idle[0]._clip.duration * idleAnimationLoopCount ) + animationActionClips.part1[0]._clip.duration;
				break;
    
			case "part2" :
				animationTimeOffset = 0;
				soundTimeOffset = 0;
				break;
			}
		} )();
		const resumeTimeLoc = this.animMixer.time + animationTimeOffset; 
		if( resumeTimeLoc ) {
			this.animMixer.setTime( resumeTimeLoc );
			this.world.currentVoiceAudioCLip.offset = resumeTimeLoc - animationTimeOffset + soundTimeOffset;
			this.update();
		}
	}
	playAnimClip( clip ) {
		if( clip === "part1" ) {
			this.currentAnimClip = animationActionClips.part1[0];
			currentAnimClip_Duration = animationActionClips.part1[0]._clip.duration;
			animationActionClips.part1.forEach( ( element ) => {
				element.repetitions = 1;
				element.play();
			} );
			this.animMixer.setTime( 0.133 );
		}
		else if ( clip === "idle" ) {
			this.currentAnimClip = animationActionClips.idle[0];
			currentAnimClip_Duration = animationActionClips.idle[0]._clip.duration;
			currentAnimClip_uuid = animationActionClips.idle[0]._clip.uuid;
			animationActionClips.idle.forEach( ( element ) => {
				element.repetitions = 1;
				element.play();
			} );
			this.animMixer.setTime( 0.1 );
		}
		else if ( clip === "part2" ) {
			this.animMixer.stopAllAction();
			this.animMixer.setTime( 0.00 );
			this.currentAnimClip = animationActionClips.part2[0];
			currentAnimClip_Duration = animationActionClips.part2[0]._clip.duration;
			animationActionClips.part2.forEach( ( element ) => {
				element.repetitions = 1;
				element.play();
			} );
		}
		currentAnimClipPart = clip;
	}
	//TODO Setup crossfade animation
	crossFadeAnimationsClips() {
		this.pauseAllAnimations( false );
		this.playAnimClip( "part2" );
	}
	placementMode( bool ) {
		if( bool ) {
			// this.TogglePlacementMaterial(true)
		}
		else if( !bool ) {
			// this.TogglePlacementMaterial(false)
		}
	}
	update() {
		// update the animation mixer
		if( this.hasAnimation ) {
			if( animationPaused ) return;
			this.animMixer.update( this.time.delta * 0.001 );
			this.calculateProgressBar( this.currentAnimClip.time );
		}
		//Look at Camera 
		if( this.headRefence ) {
			// Get temp object to look at the camera to store the Pitch, Roll and Yaw data
			this.tempObject.lookAt( this.project.camera.position );
			// Constrain the head yaw rotation 
			if( this.tempObject.rotation.y > fov.yaw[ 0 ] && this.tempObject.rotation.y < fov.yaw[ 1 ] ) {
				this.headRefence.lookAt( this.project.camera.position );
			}
			else {
			}
            
		}
	}
}